import { clientOnly } from "@solidjs/start";

export default function ClientOnlyComponent(props: {
  component: ClientComponent;
}) {
  if (props.component == ClientComponent.ModalHost) {
    const ClientOnlyModal = clientOnly(() => import("./components/_modal"));
    return <ClientOnlyModal />;
  }

  if (props.component == ClientComponent.SnackbarHost) {
    const ClientOnlySnackbar = clientOnly(
      () => import("./components/_snackbar")
    );
    return <ClientOnlySnackbar />;
  }

  const PostLogoutRedirect = clientOnly(
    () => import("~/client_only_components/post_logout_redirect")
  );
  return <PostLogoutRedirect />;
}

export enum ClientComponent {
  ModalHost = "_modal",
  SnackbarHost = "_snackbar",
  PostLogoutRedirect = "_post_logout_redirect",
}
